<template>
  <main id="main-content" :class="[overlayHeader]">
    <BreadcrumbLoader
      v-if="!hideBreadcrumbs"
      class="flex col-span-4 lg:col-span-12"
      :child-component-name="'Breadcrumbs'"
    />
    <KeepAlive>
      <Toast
        v-for="toast in toasts"
        :key="toast._uid"
        v-editable="data"
        :cookie-id="toast._uid"
        v-bind="toast"
      />
    </KeepAlive>

    <component
      :is="component"
      v-if="component"
      :id="id"
      :language-code="languageCode"
      :url="url"
      :data="page"
    />
  </main>
</template>
<script>
import { mapGetters } from 'vuex'
import Default from '@made-people/centra-storyblok-nuxt-shared/lib/pages/_'
import { joinUrlPathParts } from '@made-people/types-url-string'
export default {
  mixins: [Default],
  serverPrefetch() {
    // This is allowed if we're behind fastly or cloudflare
    if (this.$config.pageResponseCacheTtl) {
      this.$ssrContext.res.setHeader(
        'cache-control',
        `public, max-age=${this.$config.pageResponseCacheTtl}, stale-while-revalidate=600, stale-while-error=86400`
      )
    }
  },
  asyncData({ app, store, error }) {
    // No match means 404 (the route does not exist anywhere and is caught here)
    const route = app.context.route.path
    return store.dispatch('routes/lookupRoute', route).then((result) => {
      if (result === undefined) {
        error({ statusCode: 404, message: 'Page Not Found' })
      } else {
        /**
         * Trying to be compatible with the Default mixin.
         */
        return {
          seo: result.seo,
          id: result.component.id,
          component: result.component.component,
          data: result.component.data,
        }
      }
    })
  },
  head() {
    if (this.seo === undefined) {
      return {}
    }
    const link =
      this.seo.alternates?.map((alternate) => {
        return {
          rel: 'alternate',
          hreflang: alternate.hreflang,
          href: alternate.location,
        }
      }) ?? []
    const meta = []
    if (this.seo.isIndexable === false) {
      meta.push({ hid: 'robots', name: 'robots', content: 'noindex' })
      meta.push({ robots: 'noindex,nofollow' })
    }
    const isoLanguageCode = this.language?.iso639
    return {
      htmlAttrs: {
        // canonical is not set on the 'search' route since it's hardcoded in the store
        lang: this.seo.canonical?.hreflang ?? isoLanguageCode,
      },
      link,
      meta,
    }
  },
  computed: {
    ...mapGetters({
      language: 'frontend/currentLanguage',
      languageCode: 'frontend/currentLanguageCode',
    }),
    page() {
      return this.data?.content
    },
    overlayHeader() {
      if (this.page?.headerBackgroundColor === 'transparent') {
        return '-mt-[64px] lg:-mt-[72px]'
      }
      return ''
    },
    hideBreadcrumbs() {
      return this.page?.hideBreadcrumbs === true
    },
    url() {
      return (
        this.seo?.canonical?.location ??
        joinUrlPathParts(this.$config.baseUrl, this.$route.fullPath)
      )
    },
  },
  created() {
    this.$store.dispatch('ui/header/setHeaderColors', {
      bg: this.page?.headerBackgroundColor,
    })
  },
}
</script>
